<template>
  <footer id="WorkFooter">
    <img :src="cdnUrl('photos/logoP3.webp')" alt="Center Image" class="rightPhone" @click="goToHome">
  </footer>
</template>

<script>
export default {
  name: 'MyWorkFooter',
  methods: {
    goToHome() {
      this.$router.push('/');
    },
  }
}
</script>

<style scoped>
#WorkFooter {
  width: 100%;
  position: fixed;
  z-index: 10;
  bottom: 0;
  height: 32px; 
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent; 
}

.rightPhone {
  height: 40px;
  margin-right: 45px;
  margin-bottom: 70px;
}
</style>
